<template>
  <div class="survey">
      <div class="top"></div>
      <div class="content">
          <div>
              <div class="left1"></div>
                人大简介
            </div>
          <div class="con_t" @click="getsurvey1(1)">
              <div class="con_t1">
                  <img src="@/assets/images/survey/1.png" alt="">
                  娄庄镇人大简介
              </div>
              <div class="con_t2">
                  <img src="@/assets/images/survey/2.png" alt="">
              </div>
          </div>
           <div class="con_t"  @click="getsurvey2(54)">
              <div class="con_t1">
                  <img src="@/assets/images/survey/1.png" alt="">
                  娄庄镇代表工作站简介
              </div>
              <div class="con_t2">
                  <img src="@/assets/images/survey/2.png" alt="">
              </div>
          </div>
          <img class="framework" @click="getframework()" src="@/assets/images/survey/framework.png" alt="">
          <div>
              <div class="left1"></div>
              代表名单
          </div>
          <div class="title">市代表</div>
          <div class="con_list">
               <div class="con_b" v-for="(item,index) in cityImagesList" :key="index">
                    <img class="conb_img" :src="$IMG_PATH+item" alt="">
                    <div>{{cityNameList[index]}}</div>
                </div>
          </div>
         
          <div class="title">区代表</div>
          <div class="con_list">
              <div class="con_b" v-for="(item,index) in areaImagesList" :key="index">
                  <!-- {{item}} -->
                <img class="conb_img" :src="$IMG_PATH+item" alt="">
                <div>{{areaNameList[index]}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {getInform,getDetail } from "../api";
export default {
    data(){
        return{
            cityImagesList:[],
            cityNameList:[],
            areaImagesList:[],
            areaNameList:[],
        }
    },
    created() {
        this.init();
    },
    methods:{
        init(){
            this.geRrepresentative()
            this.geRrepresentatives()
        },
        geRrepresentative(){
            let params = {
                id: 87,
            };
            getDetail(params).then((res) => {
                this.areaImagesList = res.data.images;
                this.areaNameList = res.data.imagetxt;
            });
        },
         geRrepresentatives(){
            let params = {
                id: 88,
            };
            getDetail(params).then((res) => {
                this.cityImagesList = res.data.images;
                this.cityNameList = res.data.imagetxt;
            });
        },

        getsurvey1(id){
            this.$router.push({
                name: "articleDetail1",
                params: {
                    id: id,
                },
            });
        },
        getsurvey2(id){
            this.$router.push({
                name: "articleDetail1",
                params: {
                    id: id,
                },
            });
        },
        getframework(){
             this.$router.push("/organization");
        }
    }
}
</script>

<style scoped lang="less">
    .survey{
        width: 100%;
        position: relative;
        .top{
            height: 4.2rem;
            width: 100%;
            background:url('../../../assets/images/survey/top_bg.png') ;
            background-size: 100% 100%;
        }
        .content{
            width: 100%;
            padding: 0.3rem;
            color: #333333;
            font-size:0.36rem;
            font-weight: 700;
            background: #FFFFFF;
            position: absolute;
            left: 0;
            top: 2rem;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
            .left1{
                float: left;
                height: 24px;
                width: 5px;
                background: #3B93FF;
                margin-right: 0.15rem;
            }
            .con_t{
                height: 40px;
                display: flex;
                font-weight: 100;
                justify-content: space-between;
                color:#3B93FF;
                background: #FAFAFA;
                border-radius: 5px;
      align-items: center;
                margin: 0.3rem 0;        
                .con_t1{
                    img{
                        padding: 0 0.2rem;
                    }
                }
                .con_t2{
                    img{
                        padding: 0 0.2rem;
                    }
                }
            }
            .framework{
                width: 100%;
                padding: 0.1rem 0 0.4rem 0;
            }
            .title{
                width: 100%;
                color: #3B93FF;
                margin: 0.5rem 0.2rem 0.2rem;

            }
            .con_list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            .con_b{
                width: 33%;
                float: left;
                text-align: center;
                padding: 0.3rem 0;
                font-weight: 400;
                // img{
                //     margin: 0 auto;
                // }
                .conb_img{
                    height: 2.8rem;
                    width: 2rem;
                }
            }
            
        }
    }
</style>