import HTTP from '@/utils/http'
/**
 * 
 * @param {*} params 
 */


//  首页banner数据
export const getBanner = (params) => HTTP("/api/v2/ad/index",params,false,)

// 通知公告,文章列表
export const getInform = (params) => HTTP("/api/v2/article/articleList",params,false,)

// 文章详情
export const getDetail= (params) => HTTP("/api/v2/article/index",params,false,)

//投票列表
export const getVote= (params) => HTTP("/api/v2/vote/voteList",params,false,)
// 投票表单列表
export const getVoteDetail= (params) => HTTP("/api/v2/vote/index",params,false,)
// 投票
export const subVote= (params) => HTTP("/api/v2/vote/voteDo",params,false,)

export const getVoteInfo= (params) => HTTP("/api/v2/vote/options",params,false,)


//发布民意
export const eventPost= (params) => HTTP("/api/v2/event/post",params,true,)

// 意见表列表
export const getCollectList= (params) => HTTP("/api/v2/collect/collectList",params,true,)
// 意见表详情
export const getCollectDetail= (params) => HTTP("/api/v2/collect/index",params,true,)
// 发布意见征集
export const subCollect = (params) => HTTP("/api/v2/collect/post",params,true,'post')

// 问卷调查列表
export const getQuestionList= (params) => HTTP("/api/v2/questionnaire/questionList",params,false,)
export const getQuestionDetail= (params) => HTTP("/api/v2/questionnaire/index",params,false,)
export const subQuestion= (params) => HTTP("/api/v2/questionnaire/post",params,false,'post')






// export const getTest3 = function(params){
//     return HTTP('/api/community/getCommunitylist', params)
// }